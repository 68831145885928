import "./TeamInfoCard.css";

import React from "react";

export default function TeamInfoCard({ data }) {
  return (
    <>
      <div className={data.id % 2 === 0 ? "team_info_reverse": "team_infocard"}>
        <img src={data.img} alt="person" className="team_infocard_img" />

        <div className="team_content_container">
          <div className="team_infocard_name">{data.name}</div>

          <div className="team_points_container">
            <ul>
              {data.intro.map((data, index) => (
                //   <BulletPoint data={data} />
                <li className="team_intro_point">{data}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
