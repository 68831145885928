import Banner from "../AboutUs/Banner/Banner";
import "./UpdatesDetails.css";

import React from "react";
import UpdatesDetailsDisplay from "./UpdatesDetailsDisplay/UpdatesDetailsDisplay";
import UpdatesAdditionalInfo from "./UpdatesAdditionalInfo/UpdatesAdditionalInfo";

export default function UpdatesDetails() {
  return (
    <>
      <Banner banner_text="Updates" />

      <UpdatesDetailsDisplay />

      <UpdatesAdditionalInfo />
    </>
  );
}
