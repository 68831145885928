import React from 'react'

export default function AgeIconSvg() {
    return (
        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.2351 28.7976C49.3734 39.9898 40.0722 49.5985 28.2805 49.3986C17.365 49.2141 8.07922 40.2973 8.07922 28.7669C8.07922 17.6823 17.2267 7.8738 29.4643 8.27353C40.226 8.62712 49.3427 17.5901 49.2351 28.7976ZM38.7347 25.1694C38.7655 22.11 36.0289 19.6194 33.277 20.096C31.3399 20.4189 29.9409 21.4797 29.249 23.3553C28.5726 25.2155 29.0953 26.8298 30.3252 28.2596C30.7096 28.7054 30.7249 28.9821 30.3252 29.4126C29.0184 30.8577 28.6033 32.5027 29.2798 34.3784C29.9562 36.254 32.1701 37.8221 34.0457 37.6069C36.0135 37.3916 37.5356 36.4385 38.3196 34.5475C39.1037 32.6565 38.7501 30.9192 37.3972 29.4126C37.0128 28.9821 36.9821 28.69 37.3664 28.2442C38.2274 27.3371 38.7501 26.2763 38.7347 25.1694ZM23.2071 27.8445C23.2071 25.5076 23.2071 23.1862 23.2071 20.8493C23.2071 20.465 23.2379 20.0345 22.669 20.0345C21.4852 20.0345 20.3168 20.0345 19.133 20.0345C18.9178 20.0345 18.7487 20.1114 18.6564 20.342C18.2721 21.3567 18.7179 22.0024 19.7941 22.0024C19.8402 22.0024 19.9017 22.0024 19.9479 22.0024C21.1931 22.0024 21.2085 22.0178 21.2085 23.2323C21.2085 26.7375 21.2085 30.2582 21.2085 33.7634C21.2085 35.885 21.3161 35.5775 19.4713 35.5468C18.6718 35.5314 18.5949 35.9157 18.6103 36.5461C18.6257 37.1303 18.5642 37.6684 19.4559 37.653C21.2239 37.6069 22.9919 37.6376 24.7599 37.6376C25.7745 37.6376 25.7899 37.6069 25.8053 36.6076C25.8053 35.5775 25.8053 35.5775 24.7906 35.5775C24.5908 35.5775 24.3755 35.5621 24.1757 35.5775C23.4377 35.6698 23.1917 35.3315 23.2071 34.609C23.2225 32.349 23.2071 30.1044 23.2071 27.8445Z" fill="#021443" />
            <path d="M28.834 57.2547C16.95 57.3931 6.00382 49.8292 1.91437 38.5601C0.761332 35.4085 0.146377 32.18 0.177125 28.8592C0.23862 21.4644 2.71381 14.9612 7.77182 9.51888C12.8144 4.07653 19.0716 1.07863 26.4664 0.432927C31.0017 0.0332065 35.3372 0.8019 39.5342 2.49303C39.8878 2.63139 40.5028 2.69289 40.4259 3.16948C40.3183 3.83055 40.0262 4.507 39.5342 4.99897C39.3344 5.19883 39.0576 4.95284 38.827 4.8606C35.3525 3.43083 31.7243 2.69289 27.9577 2.81588C21.9158 3.03111 16.5195 5.04509 11.9074 8.96542C6.77251 13.3316 3.68237 18.8969 2.86755 25.5692C1.97587 32.8718 3.86685 39.4979 8.55588 45.2324C12.261 49.7677 16.9654 52.7809 22.6845 54.0724C30.0178 55.7327 36.8899 54.5336 43.1625 50.3365C47.913 47.1695 51.2799 42.8494 53.1708 37.4686C55.3386 31.3037 55.1848 25.1541 52.7865 19.0661C52.6328 18.6817 52.1869 18.2205 52.5251 17.8669C52.9864 17.3596 53.7089 17.1597 54.37 16.9906C54.6314 16.9291 54.7236 17.2366 54.8158 17.4518C55.9996 20.2037 56.7683 23.0633 57.0451 26.0458C57.5216 31.2422 56.7068 36.2233 54.4469 40.9123C51.0339 48.015 45.5915 52.9193 38.1967 55.6251C35.1219 56.7628 31.9703 57.3316 28.834 57.2547Z" fill="#021443" />
            <path d="M52.6021 9.57999C52.6021 10.8099 52.6021 10.8099 51.3722 10.8099C50.8648 10.8099 50.3421 10.8253 49.8348 10.8099C49.3736 10.7945 49.1891 11.0098 49.1891 11.4556C49.2044 12.1474 49.1891 12.8392 49.1891 13.5311C49.1891 13.9615 49.0046 14.1921 48.5434 14.1921C46.4064 14.1921 46.7293 14.3305 46.7139 12.4856C46.6831 10.5793 46.9445 10.8253 45.0381 10.7945C43.1318 10.7638 43.3163 11.1327 43.3163 8.91891C43.3163 8.45769 43.5315 8.27321 43.9773 8.27321C44.6384 8.27321 45.3149 8.24246 45.9759 8.27321C46.5601 8.30395 46.76 8.05797 46.7292 7.50451C46.6985 6.88956 46.7446 6.2746 46.7139 5.65965C46.6985 5.09082 46.9445 4.90633 47.4979 4.89096C49.2044 4.84483 49.1891 4.82946 49.2044 6.52059C49.2198 8.51919 48.9892 8.24246 50.9724 8.27321C51.0954 8.27321 51.2338 8.27321 51.3568 8.27321C52.6021 8.30395 52.6021 8.30395 52.6021 9.57999Z" fill="#021443" />
            <path d="M33.9228 22.1251C35.4602 22.1405 36.7054 23.3858 36.7054 24.9078C36.7208 26.4452 35.414 27.7212 33.8613 27.7212C32.2778 27.7212 31.0171 26.4606 31.0325 24.8924C31.0632 23.3397 32.3393 22.1098 33.9228 22.1251Z" fill="#021443" />
            <path d="M31.0325 32.7334C31.0171 31.2422 32.3546 29.8739 33.8613 29.8585C35.3372 29.8432 36.6593 31.1346 36.7054 32.6258C36.7516 34.1325 35.4294 35.5007 33.9074 35.5161C32.3546 35.5469 31.0632 34.2862 31.0325 32.7334Z" fill="#021443" />
        </svg>
    )
}