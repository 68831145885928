import "./PersonalLoans.css";

import React from "react";

import { LeftEclipse, PersonalLoanHand, PersonalLoanTick } from "../../../Assets";

export default function PersonalLoans() {
  return (
    <>
      <div className="p_loans_box">
        <img src={LeftEclipse} alt="" className="p_loans_rightbg_eclipse" />
        <div className="p_loans_img_container">
          <img src={PersonalLoanHand} alt="" className="p_loans_img" />
        </div>

        <div className="p_loans_content_container">
          <div className="p_loans_title">Personal <span style={{color:"#011757"}}>Loans</span></div>

          <div className="p_loans_infocard">
            <div className="p_loans_infocard_title_container">
              <img
                src={PersonalLoanTick}
                alt=""
                className="p_loans_infocard_title_logo"
              />

              <div className="p_loans_infocard_title_text">
              Personal Loan Solutions
              </div>
            </div>

            <div className="p_loans_infocard_paragraph">
            Find the perfect personal loan that fits your needs and aspirations, with flexible terms and competitive rates.
            </div>
          </div>
          <div className="p_loans_infocard">
            <div className="p_loans_infocard_title_container">
              <img
                src={PersonalLoanTick}
                alt=""
                className="p_loans_infocard_title_logo"
              />

              <div className="p_loans_infocard_title_text">
              Your Financial Flexibility
              </div>
            </div>

            <div className="p_loans_infocard_paragraph">
            Empower yourself with our range of personal loan options designed to provide you with the financial freedom you deserve.
            </div>
          </div>
          <div className="p_loans_infocard">
            <div className="p_loans_infocard_title_container">
              <img
                src={PersonalLoanTick}
                alt=""
                className="p_loans_infocard_title_logo"
              />

              <div className="p_loans_infocard_title_text">
              Borrowing Made Simple
              </div>
            </div>

            <div className="p_loans_infocard_paragraph">
            Experience a hassle-free borrowing process with our straightforward personal loan application, making your financial goals easily attainable.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
