import React from "react";
import "./header.css";
import { HeaderImage } from "../../Assets";


const Header = () => {
  return (
    <>

      <div className="home-container">
        <div className="heading-container">
          <h1 className="gradient-text">
            <span className="bold-text">Mark - Your Financial<br />Super App is here now!</span>
          </h1>
          <p>Bringing the World and Banking into your hands.</p>
          <button className="ButtonContainer">Pay - Collect - Invest</button>
        </div>
        <div className="headerImageContainer">
          <img src={HeaderImage} alt="HeaderImage" className="headerImageStyle" />
        </div>
        {/* Your other content for the Home component */}
      </div>

    </>
  );
};

export default Header;
