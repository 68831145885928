import "./GlobalPayouts.css";

import React from "react";
import { BusinessEarth } from "../../../Assets";

export default function GlobalPayouts() {
  return (
    <>
      <div className="global_payouts_box">
        <div className="global_payouts_content_container">
          <div className="global_payouts_title">
            Global Transactions for Global Businesses
          </div>

          <div className="global_payouts_paragraph">
            Effortlessly facilitate the seamless transfer, reception, and
            exchange of high-value payments, spanning across an extensive
            network of over 190 countries. Streamline your global financial
            transactions with our user-friendly platform, ensuring secure and
            efficient cross-border monetary interactions at your fingertips.
          </div>
        </div>
        <img src={BusinessEarth} alt="" className="global_payouts_img" />
      </div>
    </>
  );
}
