import "./RoutingNumber.css";

import React from "react";

import { Map, RoutingIphone } from "../../../Assets";

export default function RoutingNumber() {
  return (
    <>
      <div className="route_num_box">
      <img src={Map} alt="" className="BackClassName" />
        <img src={RoutingIphone} alt="" className="route_num_img" />

        <div className="route_num_content_container">
          <div className="route_num_title">
            Get an EU IBAN, a UK sort code, and a US routing number in your
            company name.
          </div>

          <div className="route_num_paragraph">
            Get an EU IBAN, a UK sort code, and a US routing number in your
            company name.
          </div>
        </div>
      </div>
    </>
  );
}
