import React from "react";
import "./advance.css";
import { WorldImage } from "../../Assets";
import SearchIconSvg from "../../Components/Svgs/SearchIconSvg";
import WorldIconSvg from "../../Components/Svgs/WorldIconSvg";
import CovidIconSvg from "../../Components/Svgs/CovidIcon";

const BoxStyle = ({ data }) => {
    return (
        <div className="AdvanceContentConatiner" >
            <div>{data.icon}</div>
            <h1 className="AdvanceText">{data.heading}</h1>
            <p className="AdvancePara">{data.desc}</p>
        </div>
    )
}

const Advance = () => {
    const data = [{
        id: 1,
        // image: SearchIcon,
        heading: "Behavior analysis",
        desc: "Using machine learning, we detect suspicious behavior based on website traffic and transactional data patterns.",
        icon: <SearchIconSvg />
    }
        ,
    {
        id: 2,
        // image: WorldIcon,
        heading: "Geolocation matching",
        desc: "We analyze multiple cardholder data points including billing address and issuing bank location, and check them against a website visitor's geolocation to help confirm the cardholder's identity.",
        icon: <WorldIconSvg />


    },
    {
        id: 3,
        // image: Covidicon,
        heading: "Proxy detection",
        desc: "Our advanced fraud protection tools flag transactions for review by our fraud prevention team when someone uses a proxy to make a donation on your website.",
        icon: <CovidIconSvg />

    }]
    return (
        <>
            <div className="AdnanceMainContainer">
                <div className="TextContainer">
                    <h1 className="TopHeadingConatiner">Advanced anti-fraud tools</h1>
                    <p className="TopParagraphContainer">Using machine learning and human review, we detect and mitigate fraud before it happens.</p>
                </div>
                <div className="AdvanceboxContentContainer">
                    <div className="AdvanceBoxConatiner">
                        {
                            data.map((item, index) => {
                                return (
                                    <BoxStyle data={item} />
                                )
                            })
                        }


                    </div>
                </div>
                <div className="PictureTextConatiner">

                </div>
            </div>
            <div className="ImageAndContentConatiner">
                <div className="WorldImageContainer"><img src={WorldImage} alt="World" className="WorldImageStyle" />
                </div>
                <div className="AccessheadingContainer">
                    <h1 className="AccesibilityHeadingContainer">Accessibility</h1>
                    <p className="AccesibilityParagraphContainer">At Mark, we prioritize accessibility. Our fin-tech website is thoughtfully designed to cater to diverse needs and abilities, providing an inclusive digital experience for all users. Empowering everyone to access our services effortlessly is our commitment.  </p>
                    <ul>
                        <li className="BulletContainer">Screen reader compatibility for visually impaired users. </li>
                        <li className="BulletContainer">Keyboard navigation options for users with motor disabilities. </li>
                        <li className="BulletContainer">High contrast and customizable font sizes for enhanced readability. </li>
                    </ul>
                </div>
            </div>
        </>
    )

};

export default Advance;