import React from "react";

export default function FeatureContainer({
  isRight,
  h_p1,
  b,
  h_p2,
  txt,
  img,
  noCover,
}) {
  return (
    <>
      {isRight ? (
        <div className={`features_container_feature_div_right ${img}`}>
          <div className="features_container_feature_div_info_right">
            <div className="features_container_feature_div_info_right_txt">
              <div className="features_container_feature_div_heading">
                <span>{h_p1}</span>
                {b ? <div>{h_p2}</div> : h_p2}
              </div>
              <div className="features_container_feature_div_text_right">
                {txt}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ backgroundSize: noCover ? "contain" : "" }}
          className={`features_container_feature_div ${img}`}
        >
          <div className="features_container_feature_div_info">
            <div className="features_container_feature_div_heading">
              <span>{h_p1} </span> {b ? <div>{h_p2}</div> : h_p2}
            </div>
            <div className="features_container_feature_div_text">{txt}</div>
          </div>
        </div>
      )}
    </>
  );
}
