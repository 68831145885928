import TeamInfoCard from "./TeamInfoCard/TeamInfoCard";
import "./TeamIntro.css";

import React from "react";
import { AboutUsPerson1, AboutUsPerson3, AboutUsPerson4 } from "../../../Assets";

export default function TeamIntro() {
  let data = [
    {
      id: 1,
      img: AboutUsPerson1,
      name: "Adil Khan – Founder & CEO – Mark Systems",
      intro: [
        "MBA - University of Bolton, UK",
        "Masters - International Business, Leeds University, UK",
        "BS (IT) - SZABIST, Pakistan",
        "Certified Public Accountant",
        "15+ Years Global Experience from UK, Canada, UAE, KSA & South Korea",
        "CEO - MARK Systems",
        "Director - MARKT FoodTech, Canada",
        "Director - Coding & Play - Edtech - South Korea",
        "Director - Pixxi - Proptech - UAE",
      ],
    },
    {
      id: 4,
      img: AboutUsPerson4,
      name: "Marshall Ring - Director",
      intro: [
        "Masters in Management, specializing in Economic Development - University of Manitoba, Canada.",
        "CEO - Manitoba Technology Accelerator (MTA), one of the Canada's highest performing, and Nationally recognized Technology & Commercialization Company.",
        "MTA is one of few companies which has been approved by the Government of Canada for Start Up Program.",
        "Co-founder - Manitoba Knights, an Angel investor group which invests into exciting early-stage technology companies.",
      ],
    },
    // {
    //   id: 2,
    //   img: AboutUsPerson2,
    //   name: "Usman Qureshi - CTO & Director",
    //   intro: [
    //     "MBA - West Virginia University, USA",
    //     "BS - Computer Engineering - West Virginia University, USA",
    //     "16+ years' Experience in developing Digital Banking, AML & Fraud Prevention Management, Payments Solutions for the Banking & Fintech industries.",
    //     "Mr. Usman is the Brain behind Successful Fintechs like NayaPay (Pakistan), IllicoCash - RAWBANK (Congo), Baadmay - BNPL (Pakistan)",
    //   ],
    // },
    {
      id: 3,
      img: AboutUsPerson3,
      name: "Michael Zimmel - Director",
      intro: [
        "MS - International Economics & Business - University of Innsbruck, Austria",
        "20+ Years Global Experience",
        "Founder of Volve, Singapore",
        "Group CFO - Lottoland - Gibraltar",
        "Partner - Blättchen & Partners - Investment Bank - Germany",
        "Senior Consultant - E&Y - Germany",
      ],
    },
    
  ];

  return (
    <>
      <div className="team_intro_box">
        <div className="team_title">Team</div>

        <div className="team_info_card_container">
          {data.map((data, index) => (
            <TeamInfoCard data={data} />
          ))}
        </div>
      </div>
    </>
  );
}
