import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home/Home";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer.jsx";
import AboutUs from "./Screens/AboutUs/AboutUs";
import Business from "./Screens/Business/Business";
import Investments from "./Screens/Investments/Investments";
import Personal from "./Screens/Personal/Personal";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Updates from "./Screens/Updates/Updates";
import UpdatesDetails from "./Screens/UpdatesDetails/UpdatesDetails";
import { MarkLogo } from "./Assets";
import "animate.css";

const App = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2-4 seconds for the loader
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, Math.random() * 1000 + 1000); // Random duration between 2 and 4 seconds

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  return (
    <>
      {showLoader ? (
        <div className="loader">
          <img
            src={MarkLogo}
            alt="Logo"
            className="loader-logo animate__animated animate__zoomIn"
          />
          {/* <div className="loading-line animate__animated animate__zoomIn"></div> */}

          {/* <div className="loader-icon">
            <div className="loading-spinner"></div>
          </div> */}
        </div>
      ) : null}
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/business" element={<Business />} />
          <Route path="/investments" element={<Investments />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/updates-details" element={<UpdatesDetails />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
