import "./Partnerships.css";

import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import {
  BoodmayIcon,
  FaysalBankIcon,
  HblIcon,
  JsBankIcon,
  MoneyIcon,
  MtaIcon,
  PartnershipBanner,
  TakhleeqIcon,
  TruIcon,
  VaulIcon,
  VolveIcon,
} from "../../../Assets";

export default function Partnerships() {
  let [activeState, setActiveState] = useState(1);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  let data = [
    {
      id: 1,
      img: MtaIcon,
      desc: "Manitoba Technology Accelerator (MTA), one of the Canada’s highest performing, and Nationally recognized Tech Accelerator.",
    },
    {
      id: 2,
      img: MoneyIcon,
      desc: "3S Money provides International Business accounts to expand into Global markets,190+ countries, 65+ currencies, have the widest Global coverage of any payment provider. 3S Money is registered in England & Wales and regulated by Financial Conduct Authority, UAE & regulated by Dubai Financial Services Authority (DFSA) and Luxembourg and regulated by Commission de Surveillance du Secteur Financier (CSSF).",
    },
    {
      id: 3,
      img: BoodmayIcon,
      desc: "BaadMay is a leading Buy Now Pay Later payment platform that allows you to pay for anything in three monthly installments. Baadmay host all leading Pakistani brands so simplify your shopping sprees and shop for all essentials via BaadMay.",
    },
    {
      id: 4,
      img: FaysalBankIcon,
      desc: "Faysal Funds (Faysal Asset Management Limited) is a subsidiary of Faysal Bank Limited (FBL), is a Leading Asset management company in Pakistan.",
    },
    {
      id: 5,
      img: JsBankIcon,
      desc: "JS Bank is one of the fastest growing banks in Pakistan, has domestic and international presence with over 300 branches. Part of JS Group, one of Pakistan’s most diversified and progressive financial services groups.",
    },
    {
      id: 6,
      img: TakhleeqIcon,
      desc: "Takhleeq is a versatile and dynamic branding company that provides comprehensive Brand Management & Marketing services.",
    },
    {
      id: 7,
      img: TruIcon,
      desc: "TruID is the AI based Identity & Document Verification Tech provider for Automated & Seamless KYC & AML.",
    },
    {
      id: 8,
      img: VaulIcon,
      desc: "Vaulsys, end-to-end payment solutions provider including applications and portals for front end Channels, flexible payment middleware to act as connector, and powerful backend omni channel switch coupled with a smart Anti Fraud and AML system.",
    },
    {
      id: 9,
      img: VolveIcon,
      desc: "Singapore based Volve is the Platform technology provider, tested & tried with around 50 satisfied clients in Singapore.",
    },
    {
      id: 10,
      img: HblIcon,
      desc: "HBL, Pakistan’s largest bank, with over 1,754 branches and 2,281 ATMs Globally, serving 34 million+ customers in 15 countries across Asia, Europe and Middle East.",
    },
  ];
  return (
    <>
      <div className="partnerships_box">
        <img src={PartnershipBanner} alt="" className="partnerships_bg" />
        <div className="partnerships_title">
          Right Partnerships mean we{" "}
          <span style={{ color: "#F90" }}>Grow Together</span>
        </div>

        <Swiper
          // pagination={true}
          modules={[Pagination, Autoplay]}
          spaceBetween={15}
          slidesPerView={4}
          loop={true}
          pagination={{
            clickable: true,
          }}
          className="partnerships_slideshow"
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          onSlideChange={(index) => {
            // console.log("index", index.realIndex);
            setActiveState(index.realIndex + 1);
          }}
        >
          {data.map((item) => (
            <SwiperSlide key={item.id} className="partnerships_slide">
              <div className="partnerships_img_container">
                <img
                  src={item.img}
                  alt=""
                  className="partnerships_slideshow_img"
                  onMouseOver={() => setHoveredItemId(item.id)}
                  // onMouseOut={() => setHoveredItemId(null)}
                  // onMouseOver={() => {
                  //   item.isHovering = true;
                  //   console.log("hover", item.id, "  ", item.isHovering)
                  //   // setisHoveringPartnerships((isHoveringPartnerships = true));
                  // }}
                  // onMouseOut={() => {
                  //   item.isHovering = false;
                  //   // setisHoveringPartnerships((isHoveringPartnerships = false));
                  // }}
                />
                {/* <div className="partnerships_slideshow_img_hover"></div> */}
                {hoveredItemId === item.id && (
                  <div
                    className="partnerships_slideshow_img_hover"
                    // onMouseOver={() => setHoveredItemId(item.id)}
                    onClick={() => {
                      setActiveState(item.id);
                    }}
                    onMouseOut={() => setHoveredItemId(null)}
                  ></div>
                )}
                {/* {item.isHovering === true ? (
                  <div className="partnerships_slideshow_img_hover"></div>
                ) : null} */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {data.map((item) => {
          return (
            <>
              {activeState === item.id && (
                <div className="partnerships_paragraph">{item.desc}</div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}
