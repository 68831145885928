import "./Business.css"

import React from 'react'
import BusinessHeader from "./BusinessHeader/BusinessHeader"
import PaymentAuth from "./PaymentAuth/PaymentAuth"
import CardSolutions from "./CardSolutions/CardSolutions"
import BusinessAccount from "./BusinessAccount/BusinessAccount"
import RoutingNumber from "./RoutingNumber/RoutingNumber"
import GlobalPayouts from "./GlobalPayouts/GlobalPayouts"

export default function Business() {
  return (
    <>
      <BusinessHeader />
      <PaymentAuth />
      <CardSolutions />
      <BusinessAccount />
      <RoutingNumber/>
      <GlobalPayouts/>
    </>
  )
}