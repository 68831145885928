import "./HomeVideo.css";

import React from "react";
import ReactPlayer from "react-player";
import { MarkVideo } from "../../Assets";

export default function HomeVideo() {
  return (
    <>
      <ReactPlayer
        url={MarkVideo}
        controls={false}
        playing={true}
        loop={true}
        muted={true}
        width={"100%"}
        height={"auto"}
        className="home_video_player"
      />
    </>
  );
}
