import React from "react";
import "./Partner.css";
import { Dots, True } from "../../Assets";
import SunIconSvg from "../../Components/Svgs/sun-icon-svg";
import DocumentIcon from "../../Components/Svgs/DocumentIcon";
import BiometricIconSvg from "../../Components/Svgs/BiometricIconSvg";
import AgeIconSvg from "../../Components/Svgs/AgeIconSvg";


const Partner = () => {
    return (
        <>
            <div className="bannerContainer">
                <div className="partnerMainContentContainer">
                    <div className="PartnerDotsTrueWrapper">
                        <div className="HeadingAndParaConatainer">
                            <div className="HeadingContainer1">We have Partnered with <span style={{color:"#FF9900"}}>TruID </span>to digitilize KYC</div>
                            <div className="ParaContainer1">Discover the future of KYC with our trusted partner, TruID! Streamlining and securing identity verification through digital innovation.</div>
                        </div>
                        <div className="mainDotsAndTrueConatainer">
                            <div className="DotsConatiner"><img src={Dots} alt="DotsPicture" className="DotsImageStyle" /></div>
                            <div className="TrueImageConatiner"><img src={True} alt="TruePicture" className="TrueImageStyle" /></div>
                        </div>
                    </div>
                    <div className="ParentBoxConatiner">
                        <div className="First2boxConatiner">
                            <div className="BoxContainer">
                            <div className="TickIconConatiner"><SunIconSvg/></div>
                            <div className="contentContainerAll">
                                <h1 className="UniversalHeadingClassBox">Identity Verification</h1>
                                <p className="UniversalParagrapghClassBox">Comprehensive Identity Verification is provided through liveness check, document verification and selfie-id matching</p>
                                </div>
                            </div>
                            <div className="BoxContainer">
                            <div className="BioIcon"><BiometricIconSvg/></div>
                                <h1 className="UniversalHeadingClassBox">Biometric Authentication</h1>
                                <p className="UniversalParagrapghClassBox">Securely verify your identity with Biometric Authentication document scrutiny,  and selfie-ID matching for utmost protection against fraud.</p>
                            </div>
                        </div>
                        <div className="Second2BoxConatiner">
                            <div className="BoxContainer">
                            <div className="DocumentIconStyle"><DocumentIcon/></div>
                                <h1 className="UniversalHeadingClassBox">Document Verification</h1>
                                <p className="UniversalParagrapghClassBox">Landmarks and Inbuilt Security features of the ID are examined to confirm that the card being used is an original and not a fake/copy</p>
                            </div>
                            <div className="BoxContainer">
                            <div className="AgeIconConatiner"><AgeIconSvg/></div>
                            {/* <div className="AgeIconConatiner"><img src={AgeIcon} className="AgeIconStyle" /></div> */}
                                <h1 className="UniversalHeadingClassBox">Age Verification</h1>
                                <p className="UniversalParagrapghClassBox">Our machine learning models can accurately predict the age of a person through an image to verify if it is consistent with the ID document</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Partner;
