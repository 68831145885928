import React from "react";
import "./home.css"; // You can create and import home.css if needed.
import Header from "../header/Header";
import Choose from "../ChooseUs/Choose";
import Partner from "../Partner/Partner";
import Advance from "../AdvancedAntiFraud/Advance";
import Features from "../Features/Features";
import PaymentVerify from "../PaymentsAndVerifications/PayAndVerify";
import PreventFraud from "../PreventFrauds/PreventFrauds";
import Facilities from "../Facilities/Facilities";
import BuyNow from "../BuyNow/BuyNow";
import HomeVideo from "../HomeVideo/HomeVideo";

const Home = () => {
  return (
    <>
      <Header />
      <HomeVideo />
      <Choose />
      <Partner />
      <Features />
      <PaymentVerify />
      <PreventFraud />
      <Facilities />
      <BuyNow />
      <Advance />
    </>
  );
};

export default Home;
