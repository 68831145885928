import React from "react";
import "./footer.css";
import { FacebookIcon, LinkedInIcon, MarkLogo } from "../../Assets";

const Footer = ({ visible }) => {

  return (
    <>
      <hr className="footer_linebreak" />

      <div className="footer_box">
        <div className="footer_subbox1">
          <img src={MarkLogo} alt="" className="footer_subbox1_marklogo" />
          <div className="footer_copyrights">
            © 2023 Mark
            <br />
            All rights reserved.
          </div>

          <div className="footer_social_links_container">
            <img src={FacebookIcon} alt="" className="footer_social_links" />
            <img src={LinkedInIcon} alt="" className="footer_social_links" />
          </div>
        </div>

        <div className="footer_subbox2">
          <div className="footer_subbox2_title">GET IN TOUCH</div>

          <div className="footer_subbox2_address">
            Address : G1, B 104, Block 1
            <br />
            Gulistan-e-Jauhar
            <br />
            Karachi, Pakistan
          </div>
          <div className="footer_subbox2_email">
            Email : info@marksystems.io
            <br />
            Phone : +971-561-788-423
          </div>
          {/* <div className="footer_subbox2_phone">
      </div> */}
        </div>

        <div className="footer_subbox3">
          <div className="footer_subbox3_title">SIGN UP FOR OUR NEWSLETTER</div>

          <div className="footer_subbox3_content">
            Subscribe to our newsletter to get our news & deals delivered to
            you.
          </div>

          <div className="footer_input_container">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-mail Address"
              className="footer_subbox3_input"
            />
            <button className="footer_subbox3_btn">Join</button>
          </div>
        </div>


      </div>

      <hr className="footer_linebreak" />

      <div className="footer_copyright_content">
        Copyright@2023 • Design & Developed by{" "}
        <br />
        <a href="https://www.dsmeglobal.com/" target="_blank" rel="noopener noreferrer" className="FooterCopyrightstyle">
          DSME Global Links
        </a>
      </div>

    </>
  );
};

export default Footer;