import "./AboutUs.css"

import React from 'react'
import Banner from "./Banner/Banner"
import Mission from "./Mission/Mission"
import TeamIntro from "./TeamIntro/TeamIntro"
import AdvisoryBoard from "./AdvisoryBoard/AdvisoryBoard"
import Partnerships from "./Partnerships/Partnerships"

export default function AboutUs() {
    return (
        <>
            <Banner />
            <Mission />
            <TeamIntro />
            <AdvisoryBoard/>
            <Partnerships/>
        </>
    )
}
