import "./CardSolutions.css";

import React from "react";

// import CardSolPhone from "../../../img/business_cardsolutions_phone.png";
// import CardSolCash from "../../../img/business_cardsolutions_cash.png";
// import CardSolCashHand from "../../../img/business_cardsolutions_cashhand.png";
// import CardSolHand from "../../../img/business_cardsolutions_hand.png";
// import CardSolMoneyBag from "../../../img/business_cardsolutions_moneybag.png";
import {
  BagHoverIcon,
  BagIcon,
  CardIphone,
  Change,
  DollarHoverIcon,
  DollarIcon,
  HandBagHoverIcon,
  HandIcon,
  HoverhandIcon,
} from "../../../Assets";
import { useState } from "react";

export default function CardSolutions() {
  return (
    <>
      <div className="card_sol_box">
        <div className="card_sol_content_container">
          <div className="card_sol_banner">
            Empower Your Payroll Process with Mark Systems
          </div>

          <div className="card_sol_title">
            Secure Salary Payments and Visa{" "}
            <span style={{ color: "#011757" }}>Card Solutions</span>
          </div>

          <div className="card_sol_paragraph">
            Discover Mark Systems, your comprehensive salary payment platform
            designed to facilitate secure and seamless payroll solutions.
            Experience the convenience of secure payments to employees' Staff
            Salary Accounts through Visa Cards. Elevate your payroll process
            with Mark Systems.
          </div>

          <div className="card_sol_employeebanking_container">
            <div className="card_sol_employeebanking_text">
              Employee Banking
            </div>

            <div className="card_sol_employeebanking_img_container">
              <SolutionSubCard
                title="EOSB Finance"
                img={DollarIcon}
                hoverImage={DollarHoverIcon}
              />
              <SolutionSubCard
                title="Earned Salary"
                img={HandIcon}
                hoverImage={HoverhandIcon}
              />
              <SolutionSubCard
                title="Salary Advance"
                img={BagIcon}
                hoverImage={BagHoverIcon}
              />
              <SolutionSubCard
                title="Staff Salary"
                img={Change}
                hoverImage={HandBagHoverIcon}
              />
              {/* <div className="card_sol_employeebanking_img_template">
                <div className="card_sol_image_bg">
                  <img
                    src={CardSolCash}
                    alt=""
                    className="card_sol_employeebanking_img"
                  />
                </div>

                <div className="card_sol_employeebanking_img_text">
                  EOSB Finance - B2B2C
                </div>
              </div>
              <div className="card_sol_employeebanking_img_template">
                <div className="card_sol_image_bg">
                  <img
                    src={CardSolHand}
                    alt=""
                    className="card_sol_employeebanking_img"
                  />
                </div>

                <div className="card_sol_employeebanking_img_text">
                  Earned Salary - B2B2C
                </div>
              </div> */}
              {/* <div className="card_sol_employeebanking_img_template">
                <div className="card_sol_image_bg">
                  <img
                    src={CardSolMoneyBag}
                    alt=""
                    className="card_sol_employeebanking_img"
                  />
                </div>

                <div className="card_sol_employeebanking_img_text">
                  Salary Advance - B2B2C
                </div>
              </div> */}
              {/* <div className="card_sol_employeebanking_img_template">
                <div className="card_sol_image_bg">
                  <img
                    src={CardSolCashHand}
                    alt=""
                    className="card_sol_employeebanking_img"
                  />
                </div>

                <div className="card_sol_employeebanking_img_text">
                  Investments
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <img src={CardIphone} alt="" className="card_sol_phone_img" />
      </div>
    </>
  );
}

const SolutionSubCard = ({ title, img, hoverImage }) => {
  const [image, setImage] = useState(img);
  return (
    <div className="card_sol_employeebanking_img_template">
      <div
        className="card_sol_image_bg"
        onMouseOver={() => setImage(hoverImage)}
        onMouseOut={() => setImage(img)}
      >
        <img src={image} alt="" className="card_sol_employeebanking_img" />
      </div>

      <div className="card_sol_employeebanking_img_text">{title}</div>
    </div>
  );
};
