import "./WithdrawFacility.css";

import React from "react";
import { PersonalEclipse, PersonalWithdraw } from "../../../Assets";

export default function WithdrawFacility() {
  return (
    <>
      <div className="withdraw_box">
        <img src={PersonalEclipse} alt="" className="p_investments_bg_eclipse" />
        <div className="withdraw_content_container">
          <div className="withdraw_title">
            Shariah Compliant Credit Cards <span style={{ color: "#23539C" }}> – Aligning with Your Values</span>
          </div>

          <div className="withdraw_paragraph">
            Discover a new level of financial freedom with our Shariah Compliant Credit Cards. Designed to adhere to the principles of Islamic finance, these cards offer you convenient purchasing power while upholding your ethical beliefs. Experience the best of both worlds – modern financial convenience and adherence to Shariah principles.
            <br />
            <br />
            Our Shariah Compliant Credit Cards provide you with a range of financial solutions that are ethically aligned with Islamic principles. Enjoy the convenience of credit transactions while ensuring your financial choices remain in harmony with your beliefs.
            <br />
            <br />
            Embrace a world of exclusive benefits tailored to your values. From travel privileges to lifestyle rewards, our Shariah Compliant Credit Cards offer you a host of perks that enhance your financial experiences while staying true to your ethical commitments.
          </div>
        </div>

        <div className="withdraw_img_container">
          <div className="withdraw_comingsoon_box">Coming Soon</div>

          <img src={PersonalWithdraw} alt="" className="withdraw_img" />
        </div>
      </div>
    </>
  );
}
