import "./AdvisoryBoard.css";
import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
// import { Slide } from "react-slideshow-image";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

export default function AdvisoryBoard() {
  let data = [
    {
      id: 1,
      title:
        "Dr. Abid Rafique - PhD in Engineering - Imperial College - London",
      intro: [
        "Founder & CEO of TruID - AI based Identification & Verification System for Automated KYC",
        "Professor of Electrical Engineering in National University of Sciences & Technology (NUST), Islamabad",
        "Advisor to MARK Solutions on Compliance, KYC, AML and Regulatory Affairs",
      ],
    },
    {
      id: 2,
      title:
        "Dr. Anwarullah Tayyabi – PhD in Islamic Studies - Karachi University",
      intro: [
        "Professor of Islamic studies & Shariah Compliance in Okara University",
        "Advisor to MARK Solutions on Shariah Compliant Products",
      ],
    },
    {
      id: 3,
      title: "Dr. Asma Begum – PhD in HRM – University of Bolton – UK",
      intro: [
        "Senior Lecturer, University of Westminster – London",
        "Advisor to MARK Systems on Human Resource Management, People & Culture",
      ],
    },
    {
      id: 4,
      title:
        "Amol Bahuguna – MBA – The Wharton School - University of Pennsylvania",
      intro: [
        "Senior Vice President & Head of Corporate Technology & Innovation – Riyad Bank, Saudi Arabia",
        "Advisor to MARK Solutions on Global Payments systems & Innovations ",
      ],
    },
  ];
  return (
    <>
      <div className="advisoryboard_box">
        <div className="advisoryboard_title">Advisory Board</div>

        <Swiper
          // pagination={true}
          modules={[Pagination, Autoplay]}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          spaceBetween={0}
          slidesPerView={2}
          className="mySwiper"
          autoplay= {{
            delay: 5000,
            disableOnInteraction: false
        }}
          // autoplayDisableOnInteraction : false
        >
          {data.map((data, index) => (
            <SwiperSlide key={data.id} className="advisory_swiper_card">
              <div className="advisoryboard_infocard">
                <div className="advisoryboard_infocard_title">{data.title}</div>

                <div className="advisoryboard_infocard_intro_points_container">
                  {data.intro.map((data, index) => (
                    <div className="advisoryboard_infocard_intro_points">
                      {data}
                    </div>
                  ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
