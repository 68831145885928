import React, { useState, useEffect } from "react";
import "./appNavbar.css";
import { NavLink } from "react-router-dom";
// import Select from "react-select";
import { FiAlignRight, FiX } from "react-icons/fi";
import Fade from "react-reveal/Fade";
import { MarkLogo } from "../../Assets";

function Navbar(props) {
  const [isScrolling, setIsScrolling] = useState(false);
  let [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
        setToggleMenu(false);
      } else if (window.scrollY === 0) {
        setIsScrolling(false);
      }
    });
  });

  // function scrolldiv(scroll) {
  //   var elem = document.getElementById(scroll);
  //   elem.scrollIntoView({ behavior: "smooth" });
  // }
  function handleToggleShow() {
    setToggleMenu(false);
  }

  return (
    <Fade top>
      <nav
        className={
          isScrolling
            ? "NavBar_Main_Container Active_Header"
            : "NavBar_Main_Container"
        }
      >
        <div className="logoContainer">
          <img src={MarkLogo} alt="" className="Nav_Image_Style" />
        </div>

        <div>
          <div className={"Navlink_Wrapper"}>
            <NavLink
              to="/"
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive
                    ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                    : "",
                  color: isActive ? "white" : "",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "60px",
                };
              }}
            >
              Home
            </NavLink>

            <NavLink
              to="/business"
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive
                    ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                    : "",
                  color: isActive ? "white" : "",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "60px",
                };
              }}
            >
              Business
            </NavLink>

            <NavLink
              to="/personal"
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive
                    ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                    : "",
                  color: isActive ? "white" : "",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "60px",
                };
              }}
            >
              Personal
            </NavLink>

            <NavLink
              to="/investments"
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive
                    ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                    : "",
                  color: isActive ? "white" : "",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "60px",
                };
              }}
            >
              Investments
            </NavLink>
            <NavLink
              to="/about-us"
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive
                    ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                    : "",
                  color: isActive ? "white" : "",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "60px",
                };
              }}
            >
              About Us
            </NavLink>
            {/* <NavLink
              to="/updates"
              className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive
                    ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                    : "",
                  color: isActive ? "white" : "",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "60px",
                };
              }}
            >
              Updates
            </NavLink> */}
          </div>
        </div>

        <div className={"NavLink_App_Smaller_Devices"}>
          {toggleMenu ? (
            <div className="Toggle_Btn_CLose">
              <FiX
                color="black"
                size={30}
                onClick={() => setToggleMenu(false)}
              />
            </div>
          ) : (
            <div className="Toggle_Btn_Open">
              <FiAlignRight
                color="black"
                size={30}
                onClick={() => setToggleMenu(true)}
              />
            </div>
          )}

          {toggleMenu ? (
            <div className={"NavLink_App_Smaller_Overlay"}>
              <div className={"Navlink_App_Links_Wrapper_SmallScreen_links"}>
                <NavLink
                  to="/"
                  className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
                  style={({ isActive }) => {
                    return {
                      backgroundImage: isActive
                        ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                        : "",
                      color: isActive ? "white" : "black",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "60px",
                    };
                  }}
                >
                  <span onClick={handleToggleShow}>Home</span>
                </NavLink>
                <NavLink
                  to="/business"
                  className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
                  style={({ isActive }) => {
                    return {
                      backgroundImage: isActive
                        ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                        : "",
                      color: isActive ? "white" : "black",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "60px",
                    };
                  }}
                >
                  <span onClick={handleToggleShow}>Business</span>
                </NavLink>

                <NavLink
                  to="/personal"
                  className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
                  style={({ isActive }) => {
                    return {
                      backgroundImage: isActive
                        ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                        : "",
                      color: isActive ? "white" : "black",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "60px",
                    };
                  }}
                >
                  <span onClick={handleToggleShow}>Personal</span>
                </NavLink>

                <NavLink
                  to="/investments"
                  className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
                  style={({ isActive }) => {
                    return {
                      backgroundImage: isActive
                        ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                        : "",
                      color: isActive ? "white" : "black",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "60px",
                    };
                  }}
                >
                  <span onClick={handleToggleShow}>Investments</span>
                </NavLink>

                <NavLink
                  to="/about-us"
                  className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
                  style={({ isActive }) => {
                    return {
                      backgroundImage: isActive
                        ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                        : "",
                      color: isActive ? "white" : "black",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "60px",
                    };
                  }}
                >
                  <span onClick={handleToggleShow}>About Us</span>
                </NavLink>

                {/* <NavLink
                  to="/updates"
                  className={isScrolling ? "Link_Style_Scroll" : "Link_Style"}
                  style={({ isActive }) => {
                    return {
                      backgroundImage: isActive
                        ? "linear-gradient(to right, rgb(3, 22, 74), rgb(1, 16, 57))"
                        : "",
                      color: isActive ? "white" : "black",
                      textDecoration: "none",
                      border: "none",
                      borderRadius: "60px",
                    };
                  }}
                >
                  <span onClick={handleToggleShow}>Updates</span>
                </NavLink> */}
              </div>
            </div>
          ) : null}
        </div>
      </nav>
    </Fade>
  );
}

export default Navbar;
