
import "./Personal.css";

import React from "react";
import PersonalBanner from "./PersonalBanner/PersonalBanner";
import CurrentAccount from "./CurrentAccount/CurrentAccount";
import PersonalInvestments from "./PersonalInvestments/PersonalInvestments";
import PersonalLoans from "./PersonalLoans/PersonalLoans";
// import WithdrawFacility from "../WithdrawFacility/WithdrawFacility";
import Teens from "./Teens/Teens";
import WithdrawFacility from "./WithdrawFacility/WithdrawFacility";

export default function Personal() {
  return (
    <>
      <PersonalBanner />
      <CurrentAccount />
      <PersonalInvestments />
      <PersonalLoans />
      <WithdrawFacility />
      <Teens/>
    </>
  );
}
