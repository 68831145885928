import { Map, UpdateAdditionalInfoIphone } from "../../../Assets";
import "./UpdatesAdditionalInfo.css";

import React from "react";

export default function UpdatesAdditionalInfo() {
  return (
    <>
      <div className="additional_info_box">
        <img src={Map} alt="" className="additional_info_bg_img" />

        <div className="additional_info_content_container">
          <div className="additional_info_title">Request A Demo</div>

          <div className="additional_info_paragraph">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </div>
        </div>

        <img
          src={UpdateAdditionalInfoIphone}
          alt=""
          className="additional_info_iphone_img"
        />
      </div>
    </>
  );
}
