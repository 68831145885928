import "./FacilityCard.css";

const FacilityCard = ({ data }) => {
  return (
    <>
      <div
        className={
          data.id === 4
            ? "section8_infocard_imageonly"
            : data.id === 5
            ? "section8_infocard_horizontal"
            : data.id === 2 || data.id === 8
            ? "section8_infocard_vertical"
            : "section8_infocard_horizontal"
        }
        // style={data.id == 5
        //     ? "{{ background:'#f4f9ff' }}"
        //     :""
        // }
      >
        <img
          src={data.img}
          alt=""
          className={
            data.id !== 4
              ? "section8_infocard_img"
              : "section8_infocard_img_alone"
          }
        />

        {data.id !== 4 ? (
          <div
            className={
              data.id === 5
                ? "section8_infocard_content"
                : "section8_infocard_content"
            }
          >
            {data.content}
          </div>
        ) : (
          ""
        )}
        {}
      </div>
    </>
  );
};

export default FacilityCard;
