import React from "react";
import Banner from "../AboutUs/Banner/Banner";
import "./investments.css";
import InvestmentsOurValues from "./InvestmentsOurValues/InvestmentsOurValues";
import iv1 from "../../Assets/i1.svg";
import iv11 from "../../Assets/i1.1.svg";
import iv2 from "../../Assets/iv2.1.svg";
import iv21 from "../../Assets/iv2.svg";
import iv3 from "../../Assets/iv3.1.svg";
import iv31 from "../../Assets/iv3.svg";
import iv4 from "../../Assets/iv4.1.svg";
import iv41 from "../../Assets/iv4.svg";
import iv5 from "../../Assets/iv5.1.svg";
import iv51 from "../../Assets/iv5.svg";
import iv6 from "../../Assets/iv6.1.svg";
import iv61 from "../../Assets/iv6.svg";
import handshake from "../../Assets/handshake.svg";
// import mark from "../../Assets/mark-logo.png";
import mark2 from "../../Assets/mark22.svg";
import fasalbank from "../../Assets/fasalbank.png";
import FeatureContainer from "./Feature/FeatureContainer";
import ComplaintsContainer from "./Complaints/ComplaintsContainer";
import SwiperSlideShow from "./SwiperSlideShow";

export default function Investments() {
  return (
    <div>
      <div>
        <Banner banner_text="Investments" />
      </div>
      <div className="investment_main_text">
        <p>
          <span style={{ color: "#011757" }}>Mark:</span> Crafting Financial
          Value and Transforming Lives Through Preferred Investment Solutions
        </p>
      </div>
      <div className="investments_values_main_container">
        <div className="investments_values_container">
          <InvestmentsOurValues
            icon1={iv1}
            icon2={iv11}
            heading="Integrity"
            text="We uphold the highest standards of integrity in all our actions."
          />
          <InvestmentsOurValues
            icon1={iv2}
            icon2={iv21}
            heading="Collabration"
            text="We believe in leveraging collective genius and promoting a collaborative culture."
          />
          <InvestmentsOurValues
            icon1={iv3}
            icon2={iv31}
            heading="Quality"
            text="Whatever we do, we do it best."
          />
        </div>
        <div className="investments_values_container">
          <InvestmentsOurValues
            icon1={iv4}
            icon2={iv41}
            heading="Respect for People"
            text="We value our people and enable them to grow and progress."
          />
          <InvestmentsOurValues
            icon1={iv5}
            icon2={iv51}
            heading="Customer Wellbeing"
            text="We build relationships to positively change our customers lives."
          />
          <InvestmentsOurValues
            icon1={iv6}
            icon2={iv61}
            heading="Leadership"
            text="We value thought leadership and the courage to shape a better future."
          />
        </div>
        <div className="collaboration_text">
          Collaborating for Innovative Investments:{" "}
          <span style={{ color: "#011757" }}>Mark</span> &{" "}
          <span style={{ color: "#011757" }}>Faysal Funds</span>
        </div>
      </div>
      <div className="investor_images_container">
        <div className="colaboration_img">
          <SwiperSlideShow mark={mark2} fasalbank={fasalbank} />
        </div>
        {/* <img src={colaboration} alt="" className="colaboration_img" /> */}
        <img src={handshake} className="handshake_img" alt="" />
      </div>

      <div className="complaint_div">
        <ComplaintsContainer />
      </div>

      <div className="features_container">
        <div className="features_container_heading">Features</div>
        <FeatureContainer
          h_p1={"Simplified"}
          b
          h_p2={"Account Opening"}
          txt="No documents or fields are required for account opening (Mark app
              will directly share your Name, Wallet Number & CNIC details with
              Faysal Funds)"
        />
        <FeatureContainer
          h_p1={"Flexible Investment Periods,"}
          b
          h_p2={"Consistent Profits"}
          txt=" No deduction…No Penalties!!!"
          isRight
          img={"f2img"}
        />
        <FeatureContainer
          h_p1={"Start"}
          h_p2={"Small"}
          txt=" The minimum amount you can invest/withdraw in a single transactionis PKR 100"
          img={"f3img"}
        />
        <FeatureContainer
          h_p1={"Your "}
          h_p2={"Choice"}
          txt="No Maximum limit, Invest as much as you wish and enjoy best returns"
          isRight
          img={"f4img"}
        />
        <FeatureContainer
          h_p1={"No"}
          h_p2={"Binding"}
          txt="No binding period, Yes, that's true…invest as you wish 1 day, 3 days, 1 week, 1 month, 1 year as you like it…still the same best profits."
          img={"f5img"}
          // noCover
        />
      </div>
    </div>
  );
}
