import React from "react";
import c1 from "../../../Assets/c1.png";
import c2 from "../../../Assets/c2.png";
import c3 from "../../../Assets/c3.png";

export default function ComplaintsContainer() {
  return (
    <div className="complaint_sub_div">
      <div className="complaint_div_heading">Investments</div>
      <div className="complaint_div_images">
        <img src={c1} alt="1" />
        <img src={c2} alt="2" id="img2" />
        <img src={c3} alt="3" />
      </div>
      <div className="complaint_div_info">
        <div className="complaint_div_info_heading">Shariah Complaint</div>
        <ul>
          <li>
            Invest in out innovative investment products, even if you have extra
            funds for 1 day, invest them!
          </li>
          <li>No binding</li>
          <li>No penalties</li>
          <li>Invest as you wish 1, 2, 3,….7….30 days? Choose yourself</li>
          <li>Best Returns +15% (expected profit)</li>
          <li>Taxes & other charges on us!</li>
          <li>Business Overdraft & Running Finance Facility - Coming Soon</li>
          <li>
            Business Credit Cards with 50% withdrawal facility - Coming Soon
          </li>
        </ul>
      </div>
    </div>
  );
}
