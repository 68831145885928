import "./Banner.css";

import React from "react";
import { AboutUsBanner } from "../../../Assets";

export default function Banner(props) {
  return (
    <>
      <div className="personal_banner_box">
        <img src={AboutUsBanner} alt="" className="personal_banner_bg" />

        <div className="personal_banner_title">
          {props.banner_text ? props.banner_text : "About Us"}
        </div>
      </div>
    </>
  );
}
