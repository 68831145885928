import React from "react";
import "./Facilities.css";
import { faciliyicon1, faciliyicon2, faciliyicon3, faciliyicon4, faciliyicon5, faciliyicon6, faciliyicon7, faciliyicon8 } from "../../Assets";
import FacilityCard from "./FacilityCard/FacilityCard";


const Facilities = () => {

    let data = [
        {
            id: 1,
            content: "Pay Utility Bills",
            img: faciliyicon1
        },
        {
            id: 2,
            content: "Pay Govt. Taxes",
            img: faciliyicon2
        },
        {
            id: 3,
            content: "Pay Internet Bills",
            img: faciliyicon3
        },
        {
            id: 4,
            content: "",
            img: faciliyicon4
        },
        {
            id: 5,
            content: "Recharge Prepaid Mobile",
            img: faciliyicon5
        },
        {
            id: 6,
            content: "Pay Insurance",
            img: faciliyicon6
        },
        {
            id: 7,
            content: "Pay Education Fee",
            img: faciliyicon7
        },
        {
            id: 8,
            content: "All Payment Services",
            img: faciliyicon8
        },
    ];
    return (
        <>
            <div className="section8_box">
                {data.map((data, index) => (
                    <FacilityCard data={data} />
                ))}
            </div>
        </>
    );
};

export default Facilities;
