import React from "react";
import "./Features.css";
import { ArrowIcon, PaymentIcon, RegistationIcon, TransparencyIcon } from "../../Assets";


const Features = () => {
    return (
        <>
            <div className="FourContainerParent">
                <div className="main" style={{ display: "flex" }}>
                    <div className="FirstConatinerRegistration">
                        <div className="RegistrationIcon"><img src={RegistationIcon} alt="RegistationIcon" className="RegistrationIconStyle" /></div>
                        <div>
                        <h1 className="RegistrationHeadingContainer">
                            <span style={{ color: "#12276A" }}>2-Minute</span> Registration
                        </h1>

                        <p className="RegistrationParaContainer">Sign up from your phone with just a selfie, a photo of your CNIC, and some basic information. No paperwork.</p>
                        </div>
                    </div>
                    <div className="FirstConatinerRegistration">
                        <div className="RegistrationIcon"><img src={PaymentIcon} alt="PaymentIcon" className="RegistrationIconStyle" /></div>
                        <div>
                        <h1 className="RegistrationHeadingContainer">
                            <span style={{ color: "#12276A" }}>Simple</span> Payments
                        </h1>
                        <p className="RegistrationParaContainer">Experience the ultimate convenience of swift and seamless payments with our Simple Payments service.</p>
                        </div>
                    </div>
                    <div className="FirstConatinerRegistration">
                        <div className="RegistrationIcon"><img src={TransparencyIcon} alt="TransparencyIcon" className="RegistrationIconStyle" /></div>
                        <div>
                        <h1 className="RegistrationHeadingContainer">
                            <span style={{ color: "#12276A" }}>100%</span>  Transparency
                        </h1>
                        <p className="RegistrationParaContainer">See through the clarity of every transaction with our 100% Transparency promise, just a crystal-clear view of your financial dealings</p>
                        </div>
                    </div>
                </div>
                <div className="ForthConatinerRegistration">
                    <div className="RegistrationHeadingContainer2"><img src={ArrowIcon} alt="ArrowIcon" className="ArrowIconStyle" /></div>
                </div>
            </div>
        </>
    );
};

export default Features;
