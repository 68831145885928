import "./CurrentAccount.css";

import React from "react";

import {
  LeftEclipse,
  PaymentIcon,
  PersonalMobile,
  RegistationIcon,
  TransparencyIcon,
} from "../../../Assets";

export default function CurrentAccount() {
  let data = [
    {
      id: 1,
      img: TransparencyIcon,
      title: "Efficient Banking",
      paragraph:
        "Effortlessly manage transactions, bills, and savings with our streamlined All-in-One Account.",
    },
    {
      id: 2,
      img: RegistationIcon,
      title: "Versatile Finances",
      paragraph:
        "Master your finances with our All-in-One Current Account—track spending, for financial well-being.",
    },
    {
      id: 3,
      img: PaymentIcon,
      title: "Premium Perks",
      paragraph:
        "Discover unparalleled benefits:and enjoy premium support with our All-in-One Current Account.",
    },
  ];

  return (
    <>
      <div className="current_acc_box">
        <img src={LeftEclipse} alt="" className="current_acc_leftbg_eclipse" />
        <div className="current_acc_img_box">
          <img src={PersonalMobile} alt="" className="current_acc_img" />
        </div>

        <div className="current_acc_content_container">
          <div className="current_acc_title">
            All In One{" "}
            <span style={{ color: "#23539c" }}>
              <br /> Current Account
            </span>
          </div>

          <div className="current_acc_infocard_container">
            {data.map((data, index) => (
              <div className="current_acc_infocard">
                <img
                  src={data.img}
                  alt=""
                  className="current_acc_infocard_img"
                />

                <div className="current_acc_infocard_title">{data.title}</div>

                <div className="current_acc_infocard_paragraph">
                  {data.paragraph}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
