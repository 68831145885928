import React from "react";
import "./PreventFrauds.css";
import { FraudImage } from "../../Assets";


const PreventFraud = () => {
    return (
        <>

            <div className="section6_box">
                <div>
                    <img src={FraudImage} alt="" className="section6_graphic1" />
                </div>

                <div className="section6_title">Prevent fraud before it occurs</div>

                <div className="section6_content">
                    Prevent fraud proactively with our advanced solutions. Real-time monitoring and cutting-edge technology neutralize threats before they strike. Trust us to keep your data and transactions secure.
                </div>
            </div>

        </>
    );
};

export default PreventFraud;
