import "./PersonalInvestments.css";

import React from "react";

import { DollarIcon, HandIcon, Perosnalhand, RightEclipse } from "../../../Assets";

export default function PersonalInvestments() {
  return (
    <>
      <div className="p_investments_box">
        <img
          src={RightEclipse}
          alt=""
          className="p_investments_bg_eclipse"
        />
        <div className="p_investments_content_container">
          <div className="p_investments_title"> Investments – Building Your Financial Future</div>

          <div className="p_investments_paragraph">
            Welcome to the world of possibilities and growth with our comprehensive investment offerings. At Mark, we understand that your financial goals are as unique as you are. That's why we offer a range of investment opportunities tailored to your aspirations, risk tolerance, and timeline. Whether you're planning for retirement, aiming for wealth accumulation, or seeking to achieve specific financial milestones, our investment solutions are here to guide you every step of the way.
          </div>

          {/* <div className="p_investments_points_container"> */}
          <ul className="p_investments_paragraph p_investments_point">
            <li>
            Expert insights and personalized advice.{" "}
            </li>
            <li>Commitment to your financial success </li>
            <li>Equipping you with tools for informed decisions. </li>
          </ul>
          {/* </div> */}

          <div className="p_investments_img_big_container">
            <div className="p_investments_img_container">
              <div className="p_investments_img_box">
                <img src={DollarIcon} alt="" className="p_investments_img" />
              </div>

              <div className="p_investments_img_content">EOSB Finance</div>
            </div>

            <div className="p_investments_img_container">
              <div className="p_investments_img_box">
                <img
                  src={HandIcon}
                  alt=""
                  className="p_investments_img"
                />
              </div>

              <div className="p_investments_img_content">Earned Salary</div>
            </div>
          </div>
        </div>

        <div className="p_investments_growth_img_container">
          <img
            src={Perosnalhand}
            alt=""
            className="p_investments_growth_img"
          />
        </div>
      </div>
    </>
  );
}
