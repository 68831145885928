import "./Mission.css";

import React from "react";
import { AboutUsBInoIcon, AboutUsFlagIcon, AboutUsMissionBg } from "../../../Assets";

export default function Mission() {
  return (
    <>
      <div className="mission_box">
        <img src={AboutUsMissionBg} alt="map" className="mission_bg" />

        <div className="mission_infocards_container">
          <div className="mission_infocard">
            <img src={AboutUsFlagIcon} alt="" className="mission_infocard_img" />

            <h1 className="mission_infocard_title">Mission</h1>

            <div className="mission_infocard_paragraph">
              Our goal is to provide completely digital payment solutions that
              can change to meet your Individual and Business needs. Our main
              goal is to use the most cutting-edge technologies and services to
              make a World-class experience accessible to everyone.
            </div>
          </div>

          <div className="mission_infocard">
            <img
              src={AboutUsBInoIcon}
              alt=""
              className="mission_infocard_img"
            />
            <h1 className="mission_infocard_title">Vision</h1>

            <div className="mission_infocard_paragraph">
              We want to put ourselves at the Centre of the World Economy. Offer
              Digital banking services that can be accessed without using cash
              or visiting a branch. Our plan to reach our target audience in the
              era of Global Digital transformation rests on our cutting-edge
              electronic services, which would help us establish ourselves as
              industry leaders in the Digital Banking and Financial sector.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
