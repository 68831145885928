import "./BusinessHeader.css";

import React from "react";
import { BusinessIphone } from "../../../Assets";

export default function BusinessHeader() {
  return (
    <>
      <div className="business_header_box">
        <div className="business_header_leftbox">
          <div className="business_header_title">
            Mark - Your Business{" "}
            <span style={{ color: "#F90" }}>Super App</span> is here now!
          </div>

          <div className="business_header_subtitle">
            Bringing the World & Banking into your hands.
          </div>

          <button className="business_header_btn">
            Pay - Collect - Invest
          </button>
        </div>

        <img src={BusinessIphone} alt="" className="business_header_img" />
      </div>
    </>
  );
}
