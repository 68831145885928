import { useState } from "react";
import "./UpdateStories.css";

import React from "react";
import {
  UpdateBG,
  UpdateClockIcon,
  UpdateUpdateIcon,
  UpdateArrow,
  UpdateArrowBlue,
} from "../../../Assets";
import { useNavigate } from "react-router-dom";

export default function UpdateStories() {
  let data = [
    {
      id: 1,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 2,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 3,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 4,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 5,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 6,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 7,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 8,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
    {
      id: 9,
      title:
        "Mark  Teas & Infusions Pakistan Collaborate to Financially Empower Employees",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      datetime: "June 21, 2023",
      img: UpdateBG,
    },
  ];
  return (
    <>
      <div className="update_stories_box">
        <div className="update_stories_title">
          Stories about financial wellness and newtech products to make{" "}
          <span
            style={{
              color: "white",
              backgroundColor: "#021443",
              borderRadius: "5px",
              padding: "0 0.3em 0.2em 0.3em",
            }}
          >
            your life better
          </span>
        </div>

        <div className="update_stories_subtitle">
          Subscribe to our exclusive mailing list and get the freshest stories
          from the Mark Team
        </div>

        <div className="update_stories_infocard_container">
          {data.map((item) => (
            <UpdateInfoCard data={item} />
          ))}
        </div>
      </div>
    </>
  );
}

const UpdateInfoCard = ({ data }) => {
  //   const [image, setImage] = useState(img);
  let [isHovered, setisHovered] = useState(false);
  const [Arrowimage, setArrowImage] = useState(UpdateArrowBlue);
  const navigate = useNavigate();

  return (
    <div className="update_stories_infocard">
      <img
        src={Arrowimage}
        alt=""
        className="update_stories_infocard_arrow"
        onMouseOver={() => {
          setisHovered((isHovered = true));
          setArrowImage(UpdateArrow);
        }}
        onMouseOut={() => {
          setisHovered((isHovered = false));
          setArrowImage(UpdateArrowBlue);
        }}
        onClick={() => {
          navigate("/updates-details");
        }}
      />
      <img src={data.img} alt="" className="upate_stories_img" />

      <div
        className="update_stories_infocard_overlay"
        onMouseOver={() => {
          setisHovered((isHovered = true));
          setArrowImage(UpdateArrow);
        }}
        onMouseOut={() => {
          setisHovered((isHovered = false));
          setArrowImage(UpdateArrowBlue);
        }}
        onClick={() => {
          navigate("/updates-details");
        }}
        style={{ backgroundColor: isHovered ? "#011757D4" : "" }}
      >
        <div className="update_stories_infocard_title">{data.title}</div>

        <div
          className={`update_stories_infocard_desc ${
            isHovered ? "visible" : ""
          }`}
        >
          {/* {isHovered ? data.desc : ""} */}
          {data.desc}
        </div>

        <div className="update_stories_event_details_container">
          <img
            src={UpdateClockIcon}
            alt=""
            className="update_stories_clock_img"
          />

          <div className="update_stories_date">{data.datetime}</div>

          <img
            src={UpdateUpdateIcon}
            alt=""
            className="update_stories_clock_img"
          />

          <div className="update_stories_date">Updates</div>
        </div>
      </div>
    </div>
  );
};
