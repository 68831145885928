import Banner from "../AboutUs/Banner/Banner";
import UpdateStories from "./UpdateStories/UpdateStories";
import "./Updates.css";

import React from "react";

export default function Updates() {
  return (
    <>
      <Banner banner_text={"Updates"} />
      <UpdateStories />
    </>
  );
}
