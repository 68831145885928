import "./PersonalBanner.css";

import React from "react";
import { AboutUsBanner } from "../../../Assets";

export default function PersonalBanner() {
  return (
    <>
      <div className="personal_banner_box">
        <img src={AboutUsBanner} alt="" className="personal_banner_bg" />

        <div className="personal_banner_title">Personal</div>
      </div>
    </>
  );
}
