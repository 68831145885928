import {
  BusinessAccElipseLeft,
  BusinessAccElipseRight,
  BusinessAccIcon,
  BusinessAccSeperater,
} from "../../../Assets";
import "./BusinessAccount.css";

import React from "react";

export default function BusinessAccount() {
  return (
    <>
      <div className="business_acc_box">
        <div className="business_acc_title">Mark Business Account</div>

        <div className="business_acc_subtitle">
          Your cross-border payments platform for international business, we
          support Businesses of all structures and sizes, wherever in the world
          they do business.
        </div>

        <div className="business_acc_content_container">
          <img
            src={BusinessAccElipseLeft}
            alt=""
            className="business_acc_elipse_left"
          />
          <img
            src={BusinessAccElipseRight}
            alt=""
            className="business_acc_elipse_right"
          />

          <div className="business_acc_content_details_container">
            <img
              src={BusinessAccIcon}
              alt=""
              className="business_acc_content_img"
            />

            <div className="business_acc_content_title">
              <span className="business_acc_content_title_span">
                Mark International
              </span>{" "}
              <br />
              Business Account
            </div>

            <ul className="business_acc_content_points_container">
              <li className="business_acc_content_point">
                Send, receive and exchange high-value payments in 190+ countries
              </li>

              <li className="business_acc_content_point">
                Access & Trade in 65+ currencies
              </li>

              <li className="business_acc_content_point">
                5x quicker than traditional banks
              </li>

              <li className="business_acc_content_point">
                Get an EU IBAN, a UK sort code, and a US routing number in your
                company name
              </li>
            </ul>
          </div>

          <img
            src={BusinessAccSeperater}
            alt=""
            className="business_acc_content_seperater_img"
          />

          <div className="business_acc_content_details_container">
            <img
              src={BusinessAccIcon}
              alt=""
              className="business_acc_content_img"
            />

            <div className="business_acc_content_title">
              <span className="business_acc_content_title_span">
                Mark Local
              </span>{" "}
              <br />
              Business Account
            </div>

            <div className="business_acc_content_point">
              Business Current Account is an account designed to meet your daily
              Business needs. With a host of free banking services; we ensure
              that all your banking and financial needs are well taken care of
              so that you can focus on taking your business to newer heights.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
