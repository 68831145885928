import React from "react";
import "./BuyNow.css";
import { BuyNow1, BuyNowBanner, BuyNowIcon1, BuyNowIcon2, BuyNowIcon3, BuyNowPhone } from "../../Assets";


const BuyNow = () => {
    return (
        <>
            <div className="section7_box">
                <div>
                    <img src={BuyNowBanner} alt="" className="section7_bg_img" />
                </div>
                <div className="section7_subbox1">
                    <div className="section7_subbox1_title">Buy Now Pay Later</div>

                    <img src={BuyNow1} alt="" className="section7_subbox1_img" />
                </div>

                <div className="section7_subbox2">
                    <div className="section7_subbox2_infocard">
                        <img src={BuyNowIcon1} alt="" className="section7_subbox2_infocard_logo" />

                        <div className="section7_subbox2_infocard_content_container">
                            <div className="section7_subbox2_infocard_title">
                                Shop now at your
                            </div>

                            <div className="section7_subbox2_infocard_content">
                                Favorite Brands
                            </div>
                        </div>
                    </div>
                    <div className="section7_subbox2_infocard">
                        <img src={BuyNowIcon2} alt="" className="section7_subbox2_infocard_logo" />

                        <div className="section7_subbox2_infocard_content_container">
                            <div className="section7_subbox2_infocard_title">Pay in</div>

                            <div className="section7_subbox2_infocard_content">
                                3 easy installments
                            </div>
                        </div>
                    </div>
                    <div className="section7_subbox2_infocard">
                        <img src={BuyNowIcon3} alt="" className="section7_subbox2_infocard_logo" />

                        <div className="section7_subbox2_infocard_content_container">
                            <div className="section7_subbox2_infocard_title">Claim your</div>

                            <div className="section7_subbox2_infocard_content">
                                shopping limit upto Rs 50,000
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <img src={BuyNowPhone} alt="" className="section7_graphic2" />
                </div>
            </div>


        </>
    );
};

export default BuyNow;
