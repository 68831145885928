import React from "react";
import "./Choose.css";
import { AiImage, BiometricImage, ProtectionImage } from "../../Assets";

const BoxStyle = ({data}) => {
    return (
        <div className={ data.id === 1  ?"ChooseUsContentConatiner1" : data.id === 2 ?"ChooseUsContentConatiner2" : data.id ===3 ? "ChooseUsContentConatiner3" : null  }>
            <img src={data.image} alt=""/>
            <h1 className="ChooseUsText">{data.heading}</h1>
            <p className="ChooseUsPara">{data.desc}</p>
        </div>
    )
}

const Choose = () => {

    const data = [{
        id: 1,
        image:ProtectionImage,
        heading:"Ultimate Security",
        desc:"We protect your funds and sensitive personal data with encryption in-flight and at-rest."
    }
        ,
    {
        id: 2,
        image:BiometricImage,
        heading:"AI based KYC & Biometric Verification",
        desc:"Use fingerprint or Face ID for quick and easy access to your account information."


    },
    {
        id: 3,
        image:AiImage,
        heading:"AI based AML & Fraud Prevention System",
        desc:"We've implemented best-in-class fraud detection systems to keep your money safe."

    }]
    return (
        <>

            <div className="ChooseUs">
                <h1 className="ChooseUsHeading">Why Choose Mark</h1>
                <div className="ChooseUsBoxConatiner">
                    {
                        data.map((item, index) =>
                        {
                            return(
                                <BoxStyle data={item}/>
                            )
                        })
                    }

                
                </div>
            </div>


        </>
    );
};

export default Choose;