import React, { useState } from "react";
import "./InvestmentsOurValues.css";
export default function InvestmentsOurValues(props) {
  let [selectedIcon, setselectedIcon] = useState(props.icon2);
  function onHover() {
    setselectedIcon((selectedIcon = props.icon1));
  }
  function onleave() {
    setselectedIcon((selectedIcon = props.icon2));
  }
  return (
    <div
      className="values_container"
      onMouseEnter={onHover}
      onMouseLeave={onleave}
    >
      <div className="value_svg">
        <img
          src={selectedIcon}
          alt="pp"
          height={props.h ? props.h : 90}
          width={props.w ? props.w : 110}
        />
      </div>
      <div className="text_container">
        <div className="value_heading">
          {props.heading ? props.heading : "heading"}
        </div>
        <div className="value_text">
          <p>{props.text ? props.text : "text"}</p>
        </div>
      </div>
    </div>
  );
}
