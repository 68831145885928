import React from "react";
import "./PayAndVerify.css";
import { Icon1, Icon2, Icon3, Icon4, Icon5, Phone1, Phone2, Phone3, Phone4, Phone5 } from "../../Assets";


const PaymentVerify = () => {
    return (
        <>
            <div className="section5_box">
                <div className="section5_title_style">30 Second</div>

                <div className="section5_infocards_container">

                    <div className="section5_background">

                    </div>
                    <div className="section5_infocard">
                        <img src={Icon1} alt="" className="section5_num_img" />

                        <div className="section5_infocard_title"><span style={{ color: "#12276A" }}>Simple</span> Payments</div>

                        <img src={Phone1} alt="phone" className="section5_phone_img" />
                    </div>

                    <div className="section5_infocard">
                        <img src={Icon2} alt="" className="section5_num_img" />

                        <div className="section5_infocard_title" style={{ width: "70%" }}><span style={{ color: "#12276A" }}>Identity</span> Document Verfication</div>

                        <img src={Phone2} alt="phone" className="section5_phone_img" />
                    </div>

                    <div className="section5_infocard">
                        <img src={Icon3} alt="" className="section5_num_img" />

                        <div className="section5_infocard_title"><span style={{ color: "#12276A" }}>ID Selfie</span> Matching</div>

                        <img src={Phone3} alt="phone" className="section5_phone_img" />
                    </div>

                    <div className="section5_infocard">
                        <img src={Icon4} alt="" className="section5_num_img" />

                        <div className="section5_infocard_title"><span style={{ color: "#12276A" }}>Online</span> Biometrics</div>

                        <img src={Phone4} alt="phone" className="section5_phone_img" />
                    </div>

                    <div className="section5_infocard">
                        <img src={Icon5} alt="" className="section5_num_img" />

                        <div className="section5_infocard_title"><span style={{ color: "#12276A" }}>Date</span> Extraction</div>

                        <img src={Phone5} alt="phone" className="section5_phone_img" />
                    </div>
                </div>
            </div>

        </>
    );
};

export default PaymentVerify;
