import "./PaymentAuth.css";

import React from "react";
import { BusinessAuth } from "../../../Assets";

export default function PaymentAuth() {
  return (
    <>
      <div className="business_paymentauth_box">
        <img src={BusinessAuth} alt="" className="business_paymentauth_img" />

        <div className="business_paymentauth_content">
          Mark developed AI-driven automation of 80% of all Corporate payments.
        </div>
        <div className="business_paymentauth_content">
          MarkThe goal is to fully automate Business’ payment processes and
          related internal controls using Advanced Data Analytics.
        </div>
        <div className="business_paymentauth_content">
          All-in-one Embedded Finance, by directly integrating payments Mark
          fills the control gap in Banking and Accounting systems. We build to
          cover all payment rails from cards to banks on a single platform. Cash
          Management, Accounting, Tax, Reporting and Compliance….everything!
        </div>
        <div className="business_paymentauth_content">
          Promoting Cash-less culture for Corporate Spending with Visibility and
          Control no matter where you are.
        </div>
      </div>
    </>
  );
}
