import "./Teens.css";

import React, { useState } from "react";
import { LeftEclipse, PersonalTeenInfo, TransparencyIcon } from "../../../Assets";

const Teens = () => {
  const data = [
    {
      // isClicked: true,
      id: 1,
      img: TransparencyIcon,
      title: "Financial Education Journey",
      paragraph:
        "Embark on a journey of financial education, empowering you with knowledge for a secure future.",
    },
    {
      // isClicked: false,
      id: 2,
      img: TransparencyIcon,
      title: "Managed Autonomy and Perks",
      paragraph:
        "Experience guided freedom and enjoy rewards with our offering of Managed Autonomy and Perks.",
    },
    {
      // isClicked: false,
      id: 3,
      img: TransparencyIcon,
      title: "Debit Card Empowerment",
      paragraph:
        "Empower your transactions with our Debit Card solution, offering convenience and control.",
    },
  ];

  let [TeensCardClicked, setTeensCardClicked] = useState(1);

  function TeensCardClick(num) {
    setTeensCardClicked((TeensCardClicked = num));
    console.log("data", TeensCardClicked);
  }

  return (
    <>
      <div className="teens_box">
      <img src={LeftEclipse} alt="" className="p_loans_rightbg_eclipse" />
        <div className="teens_img_container">
          <img src={PersonalTeenInfo} alt="" className="teens_img" />
        </div>

        <div className="teens_content_container">
          <div className="teens_title">
            Account for
            <br />
            <span style={{ color: "#011757" }}>Teens</span>
          </div>

          <div className="teens_infocards_container">
            {data.map((item, index) => {
              return (
                <>
                  {TeensCardClicked === item.id ? (
                    <div
                      className="teens_infocard"
                      onClick={() => {
                        TeensCardClick(item.id);
                      }}
                    >
                      <div className="teens_title_container">
                        <img
                          src={item.img}
                          alt=""
                          className="teens_title_logo"
                        />

                        <div className="teens_infocard_title">{item.title}</div>
                      </div>

                      <div className="teens_infocard_paragraph">
                        {item.paragraph}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="teens_infocard"
                      style={{ backgroundColor: "#091E5F" }}
                      onClick={() => {
                        TeensCardClick(item.id);
                      }}
                    >
                      <div className="teens_title_container">
                        <img
                          src={item.img}
                          alt=""
                          className="teens_title_logo"
                        />

                        <div
                          className="teens_infocard_title"
                          style={{ color: "white" }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Teens;
