import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./SwiperSlide.css";

export default function SwipperSlideShow({ fasalbank, mark }) {
  let data = [
    {
      id: 1,
      img: fasalbank,
    },
    {
      id: 2,
      img: mark,
    },
    {
      id: 3,
      img: fasalbank,
    },
    {
      id: 4,
      img: mark,
    },
    {
      id: 5,
      img: fasalbank,
    },
    {
      id: 6,
      img: mark,
    },
    {
      id: 7,
      img: fasalbank,
    },
    {
      id: 8,
      img: mark,
    },
    {
      id: 9,
      img: fasalbank,
    },
    {
      id: 10,
      img: mark,
    },
  ];
  return (
    <>
      <Swiper
        // pagination={true}
        modules={[Pagination, Autoplay]}
        spaceBetween={-10}
        slidesPerView={5}
        className="investment_slidesho"
        loop={true}
        autoplay
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="p_img_container">
              <img
                src={item.img}
                alt=""
                height={item.id / 2 === 0 ? 70 : ""}
                width={item.id / 2 === 0 ? "200px" : ""}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
